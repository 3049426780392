
<template>
  <div id="app" class="body">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
		step: 0,
		dome: [],
		key:""
    }
  },
  mounted(){
	  const params = this.getUrlParam("type");
	  if (params) {
	  	this.key = params;
		this.queryUrl();
	  }
  },
  methods:{
	  queryUrl(){
		  this.axios.get("/apiM/queryAccurateUrlByType",{params:{url_type:this.key}}).then((response) => {
			const res = response.data.data.filter(el=>(el.url_status === 0));
			if(res.length <= 0) return;
		  	this.dome = res;
		    this.domeEarch();
		  })
	  },
	  async domeEarch() {
	  	if (this.step > (this.dome.length - 1)) return;
	  	await this.domeAjax();
	  },
	  updateUrl(url,status){
		  this.axios.post("/apiM/updateUrlStatusByUrl",{url,status}).then((response) => {
		  	this.domeEarch();
		  })
		  
	  },
	 async domeAjax() {
	  	const t = 0;
	  	const old = "/apiJc/api/v3/safebrowsing/status";
	  	const meApi = "https://qt.t21bk.top/api/v1/checkdomain";
	  	const url = t === 0 ? old : meApi;
	  	this.axios.get(url,{params:{site:this.dome[this.step].url}}).then((response) => {
	
			const res = JSON.parse(JSON.stringify(response.data));
			const resSplit = res.split(' ')[0];
			const currentArray = JSON.parse(resSplit.split('\n\n')[1])[0];
			if (currentArray[2] || currentArray[3] || currentArray[4] || currentArray[
					5] ||
				currentArray[6]) {
				this.$set(this.dome[this.step],"url_status",1);
				if (this.step >= (this.dome.length - 1)) {
					// window.location.href = this.dome[this.dome.length - 1].url;
					// console.log("都红了旧取最后一条：" + this.dome[this.dome.length - 1].url);
				} else {
					setTimeout(()=> {
						// console.log("红了：" + currentArray[8]);
						this.updateUrl(currentArray[8],1);
					}, 1000)
				}
				++this.step;
			} else {
				window.location.href = currentArray[8];
				// console.log("域名未报红", currentArray[8]);
			}
	  	}).catch(err=>{
			setTimeout(()=> {
				this.domeEarch();
			}, 1000)
			// console.log("请求失败",err)
		})
	  },
	  getUrlParam(name) {
	  	const urlinfo = window.location.href; //获取url
	  	let urlName = "";
	  	if (name == "type") {
	  		urlName = urlinfo.split("?")[1].split("=")[1].split("&")[0]; //拆分url得到”=”后面的参数
	  	}
	  	return decodeURI(urlName);
	  }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
* {
				margin: 0;
				padding: 0;
			}

			.body {
				min-height: calc(100vh);
			}

			.loader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.loader:before,
			.loader:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 0.5em;
				height: 0.5em;
				border-radius: 0.25em;
				transform: translate(-50%, -50%);
			}

			.loader:before {
				animation: before 2s infinite;
			}

			.loader:after {
				animation: after 2s infinite;
			}

			@keyframes before {
				0% {
					width: 0.5em;
					box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
				}

				35% {
					width: 2.5em;
					box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
				}

				70% {
					width: 0.5em;
					box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
				}

				100% {
					box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
				}
			}

			@keyframes after {
				0% {
					height: 0.5em;
					box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
				}

				35% {
					height: 2.5em;
					box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
				}

				70% {
					height: 0.5em;
					box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
				}

				100% {
					box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
				}
			}
</style>
